import React from "react";
import Footer from './Footer'
import NaviBarv2 from './NaviBarv2'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles.css";
import {Container, Row, Col, Card, ListGroup, ListGroupItem, Button, Tab, Nav,
OverlayTrigger, Popover, Placeholder} from 'react-bootstrap';
import Krivorotko from "../images/team/Krivorotko.png"

export function Krivorotko_info () {
  return (
        <>
    <NaviBarv2 />
    <Container>
      <section>
        <div><img src={Krivorotko} align="left" width={320} ></img></div>
        <div class="row my-4 ps-4"><h1>Криворотько Ольга Игоревна</h1></div>
        <div class="row ps-4"><p>Доктор физико-математических наук</p></div>
        <div class="row ps-4"><p>Заведующий лабораторией</p><hr></hr></div>
        <div class="row ps-4"><h5>📩 krivorotko.olya@mail.ru</h5>
        <h5>📩 o.i.krivorotko@math.nsc.ru</h5><hr></hr>
        </div>
        <div class="row">
        <div class="col-sm-5 col-md-6 ps-5">WOS Research ID:<a href="https://www.webofscience.com/wos/author/record/E-5775-2014">E-5775-2014</a></div>
        <div class="col-sm-5 offset-sm-2 col-md-6 offset-md-0 ">ID РИНЦ:<a rel="noopener" target="_blank" href="https://www.elibrary.ru/author_profile.asp?authorid=746714">746714</a></div>
        </div>
        <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-6 mx-auto ps-5 ">Scopus Author ID:<a href="https://www.scopus.com/authid/detail.uri?authorId=57217457913">57217457913</a><hr></hr></div>
        <div class="col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0">ORCID: <a href="https://orcid.org/0000-0003-0125-4988">0000-0003-0125-4988</a><hr></hr></div>
        </div>
        <div class="row ps-4"><p><a href="https://ai-biolab.ru/data/Резюме-Криворотько_2023 .pdf">Резюме</a></p></div>
        <div class="row ps-4"><p>03.04.1989</p></div>
        </section>
        <hr></hr>
        <div class="row">
        <div class="col-sm-5 col-md-6"><div class="my-3"><h3>Образование</h3></div>
        <p>2006-2011 г. — Студент специалист Новосибирского государственного университета, Россия. Название дипломной работы: решение обратной задачи термоакустики. Научный руководитель: член-корр. РАН, профессор С.И. Кабанихин.</p>
        <p>2011-2014 г. — Аспирант Новосибирского государственного университета, Россия. Название кандидатской диссертации: Регуляризация задач определения источников колебаний. Научный руководитель: член-корр. РАН, профессор С.И. Кабанихин.</p>
        <p>2015 г. — Кандидат физико-математических наук по специальности 05.13.18 – математическое моделирование, численные методы и комплексы программ. </p>
        <p>2023 г. — Доктор физико-математических наук по специальности 1.2.2. - математическое моделирование, численные методы и комплексы программ.</p>
        </div>

        <div class="col-sm-5 offset-sm-2 col-md-6 offset-md-0"><div class="my-3"><h3>Профессиональный опыт</h3></div>
        <p> 2024 г.-н.вр. —  Заведующий лабораторией ИИ-технологий математического моделирования биологических, социально-экономических и экологических процессов, Институт математики им. С.Л.Соболева СО РАН.</p>
        <p> 2023 г.-н.вр. —  Начальник научно-исследовательского отдела Международного математического центра, Институт математики им. С.Л. Соболева СО РАН.</p>
        <p> 2023 г.-н.вр. — Старший научный сотрудник, Институт математики им. С.Л. Соболева СО РАН.</p>
        <p> 2021 г.-н.вр. — Доцент кафедры высшей математики физического факультета, Новосибирский государственный университет, Новосибирск, Россия.</p>
        <p> 2015 г.-н.вр. — Доцент кафедры математических методов геофизики механико-математического факультета Новосибирского государственного университета, Новосибирск, Россия. Численные методы решения обратных и некорректных задач, годовой спецкурс.</p>
        <p> 2015 г.-н.вр. — Заместитель заведующего совместной лабораторией ННЦ-НГУ «Методы создания, исследования и идентификации математических моделей естествознания», Новосибирский государственный университет, Новосибирск, Россия.</p>
        <p> 2014 - 2022 г. — Заместитель Председателя совета научной молодежи Института вычислительной математики и математической геофизики СО РАН, Новосибирск, Россия. </p>
        <p> 2014 - 2022 г. — Старший научный сотрудник, Лаборатория математических задач геофизики, Институт вычислительной математики и математической геофизики СО РАН, Новосибирск, Россия. Исследователь в области прямых и обратных задач математической физики (геофизики, акустики, сейсмики), медицины и связанных с ними областей науки, задач об источнике (определение источников цунами, землетрясений, задача термоакустики), численное решение прямых и обратных задач иммунологии и эпидемиологии, цифровая экономика.</p>
        <p> 2012 - 2014 г. — Инженер, Институт вычислительной математики и математической геофизики СО РАН, Новосибирск, Россия. Исследователь в области прямых и обратных задач математической физики (геофизики, акустики, сейсмики), медицины и связанных с ними областей науки.</p>
        <p> 2011 - 2021 г. — Старший преподаватель кафедры высшей математики физического факультета, Новосибирский государственный университет, Новосибирск, Россия. Векторный и тензорный анализ (3 курс).</p>
        <p> 2011 - 2014 г. — Инженер-программист, ООО «ГеоСистема», Новосибирск, Россия. Один из разработчиков наукоемкого программного продукта ITRIS, предназначенный для моделирования природных и техногенных катастроф (цунами, землетрясения и пр.). Разработчик-исследователь в области прямых и обратных задач математической физики (сейсмики, электроразведки), медицины, обработки изображений.</p>
        <p> 2011 - 2015 г. — Ученый секретарь третьей, четвертой, пятой, шестой и седьмой международной молодежной научной школы-конференции «Теория и численные методы решения обратных и некорректных задач», Новосибирск, 10-15 октября 2011, 5-15 августа 2012, 8-13 октября 2013, 8-14 декабря 2014, 19-24 октября 2015, соответственно.</p></div>
        </div>
        <hr></hr>
        <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-6 mx-auto"><div class="my-3"><h3>Преподавание</h3></div>
        <p>Численные методы решения обратных и некорректных задач - годовой спецкурс кафедры математических методов геофизики ММФ НГУ, 3-6 курсы.</p>
        <p>Векторный и тензорный анализ - лекции и семинары, 3 курс физического факультета НГУ.</p>
        <Button href="/Krivorotko_Zvonareva_teaching" className="text-white shadow4 mp_info" variant="info" size="sm">
            <div style={{fontSize: 16}}><b>Материалы</b></div></Button>
            <div class="row ps-4 my-3"><p class="lead"></p></div>
        <p>Обратные задачи: теория и практика - курс на <a rel="noopener" target="_blank" href="https://cytometry.ru/biomed/schedule.html">кафедре биомедицинской физики Новосибирского государственного университета</a> для магистрантов 5-6 курса.</p>
        <Button href="/Krivorotko_teaching" className="text-white shadow4 mp_info" variant="info" size="sm">
            <div style={{fontSize: 16}}><b>Материалы</b></div></Button>
            <div class="row ps-4 my-3"><p class="lead"></p></div>
        <p>Научный руководитель 6 студентов (2 аспиранта, 1 магистрант, 3 бакалавра).</p></div>
        <div class="col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0"><div class="my-3"><h3>Профессиональные интересы</h3></div>
        <p>Обратные и некорректные задачи в науках о Земле и биологии.</p>
        <p>Идентифицируемость и анализ чувствительности.</p>
        <p>Оптимизация и регуляризация.</p>
        <p>Машинное обучение с точки зрения оптимизации.</p>
        <p>Высокопроизводительные вычисления.</p>
        <p>Эпидемиология, иммунология и социальные процессы.</p>
        </div>
        <hr></hr>
        <div class="row">
          <div class="my-3"><h3>Исследовательские проекты и гранты</h3></div>
          <p>РФФИ 09-01-00746 «Создание новых численных методов и изучение известных методов решения обратных задач математической физики» (2009-2011) — исполнитель.</p>
          <p>РФФИ 12-01-00773 «Теория и численные методы решения обратных задач математической физики» (2012-2014) — исполнитель.</p>
          <p>СО РАН 14 «Обратные задачи и их приложения: теория, алгоритмы и комплекс программ» (2014) — исполнитель.</p>
          <p>РФФИ 14-01-31182 «Оптимальное управление формой тонких включений в задачах теории упругости» (2014-2015) — исполнитель.</p>
          <p>РФФИ 15-01-09230 «Построение и исследование аналогов уравнений И.М. Гельфанда, Б.М. Левитана, М.Г. Крейна и численных методов их решения в применении к многомерным обратным задачам акустики, электродинамики и теории упругости» (2015-2017) — исполнитель.</p>
          <p>РФФИ 16-31-00189 «Развитие параллельных алгоритмов численного решения прямых и обратных задач распространения волн в системе гидросфера-литосфера» (2016-2017) — руководитель.</p>
          <p>РФФИ 16-31-00382 «Разработка численных алгоритмов решения прямых и обратных задач в биологии и медицине» (2016-2017) — исполнитель.</p>
          <p>РФФИ 16-01-00755 «Разработка численных методов продолжения решений с части границы уравнений математической физики» (2016-2018) — исполнитель.</p>
          <p>Грант Президента Российской Федерации MK-1214.2017.1 «Исследование и разработка численных алгоритмов решения прямых и обратных задач иммунологии и эпидемиологии» (2017-2018) — руководитель.</p>
          <p>РФФИ 17-52-14004 «Методы реконструкции ПЭТ и ОФЭКТ изображений высокого качества при низкой статистике гамма квантов» (2017-2019) — исполнитель.</p>
          <p>РФФИ 17-51-540004 «Обоснование существующих и разработка новых численных методов решения обратных и некорректных задач для эллиптических и параболических уравнений» (2017-2018) — исполнитель.</p>
          <p>РНФ 18-71-10044 «Суперкомпьютерный анализ социальных, эпидемиологических и экономических процессов. Теория, алгоритмы и комплекс программ» (2018-2023) — руководитель.</p>
          <p>РФФИ 18-41-540017 «Разработка методов компьютерного моделирования и комплекса программ для супер-ЭВМ в целях вероятностного прогнозирования аномальных и опасных природных явлений в Новосибирской области» (2018-2020) - исполнитель.</p>
          <p>РФФИ 18-31-20019 «Прямые и обратные задачи социальных процессов: теория, алгоритмы и комплекс программ» (2018-2020) — руководитель.</p>
          <p>Грант Президента Российской Федерации MK-814.2019.1 «Анализ и применение методов машинного обучения в обратных задачах с использованием параллельных вычислений на супер-ЭВМ» (2019-2020) — руководитель.</p>
          <p>РФФИ 21-51-10003 «Обратное математическое моделирование в эпидемиологии» (2021-2022) — руководитель.</p>
          <p>Грант Президента Российской Федерации МК-4994.2021.1.1 «Агентное моделирование и прогнозирование распространения коронавирусной эпидемии в регионах Российской Федерации с учетом анализа эффективности карантинных мер» (2021-2022) — руководитель.</p>
          <p>РНФ 23-71-10068 «Разработка интеллектуальной программной системы мониторинга и моделирования взаимосвязанных эпидемиологических и экономических процессов в Российской Федерации» (2023-2026) — руководитель.</p>
          <p>Грант Правительства Новосибирской области молодым ученым "Исследование эпидемиологии микобактериальных инфекций человека и сельскохозяйственных животных с применением методов математического моделирования, машинного обучения и ГИС-технологий: значение для здравоохранения и животноводства Новосибирской области" (2024) - руководитель.</p>
        </div>
        <hr></hr>
        <div class="row">
        <div class="my-3"><h3>Награды и достижения</h3></div>
        <p>Премия Правительства Новосибирской области за выдающиеся научные достижения в номинации «Лучший молодой исследователь» за работа "Программный комплекс моделирования и построения сценариев распространения инфекционных заболеваний в Новосибирской области с учетом социально-экономических ограничений" (2023).</p>
        <p>Премия имени Г.И. Марчука за работу «Идентифицируемость математических моделей иммунологии и эпидемиологии» (2021).</p>
        <p>Премия мэрии города Новосибирска в сфере науки и инноваций. Номинация «Лучший молодой исследователь в организациях науки» за разработку карты прогноза распространения социально-значимых заболеваний в городе Новосибирске (2020).</p>
        <p>Грант УМНИК за проект «Разработка наукоемкой 3D интеграционной системы визуализации и предупреждения цунами» (2015).</p>
        <p>Вторая премия Лаврентьевского конкурса студенческих и аспирантских работ по математике и механике «Исследование совмещенной обратной задачи восстановления источника возмущения водной поверхности» (2014).</p>
        <p>Стипендия компании British Petroleum для аспирантов (2013-2014).</p>
        <p>Первая премия Лаврентьевского конкурса студенческих и аспирантских работ по математике и механике «Исследование задач определения источников волновых процессов» (2013).</p>
        <p>Стипендия Президента РФ (2012-2013).</p>
        <p>Вторая премия Лаврентьевского конкурса студенческих и аспирантских работ по математике и механике за решение обратной задачи термоакустики (2011).</p>
        <p>Премия компании Бэйкер Хьюз («Baker Hughes BV»): Определение источников цунами и землетрясений (2011-2012).</p>
        <p>Премия компании Бэйкер Хьюз («Baker Hughes BV»): Исследование и решение обратной задачи термоакустики методом сингулярного разложения (2010-2011).</p>
        </div>
        </div>
        </Container>
        

    <Footer />
    </>
    
    )}
    
    